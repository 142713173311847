import React from "react";
import Information from "./components/information";
import AccountList from "./components/account-list";

const Profile = () => {
  return (
    <div
      className={"h-screen flex-1 p-10 dark-purple"}
      style={{ backgroundColor: "#DBE2EF" }}
    >
      Profile Information
      <Information/>
      
      Account Credentials
      <AccountList/>
    </div>
  );
};

export default Profile;
