import React from "react";
import axios from "axios";
import {baseUrl} from "../../../../api"

const EditDrawer = ({ company, isOpen, setIsOpen }) => {



  const updateCompany = async () => {
    try{

    }
    catch(e){

    }

  }


  const handleSubmit = async (event,id) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const newUser = {
      name: {
        first: formData.get("firstName"),
        last: formData.get("lastName")
      },
      email: formData.get("email"),
      password: formData.get("password"),
      role: 'client',
      company: id,
      
      
    };

    try {
      await axios.post("http://localhost:3001/companies/users/create", newUser);
      alert("Client added successfully!");
      
      window.location.reload();
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };


  


  

 


  


  return (
    <main
      className={
        "shadow fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0 "
          : " transition-opacity opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          " w-screen max-w-6xl right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform    " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-full pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <header className="p-4 font-normal text-lg">Edit Information</header>
           {/* COMPANY INFORMATION */}
           {company ? company.name : null}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}



export default EditDrawer