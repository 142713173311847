import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addCompany } from "../../../../features/companies/companiesSlice";

const AddClientForm = ({ onClose }) => {

  

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const newClient = {
      name: formData.get("name"),
      website: formData.get("website"),
      description: formData.get("description"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      address: {
        street: formData.get("street"),
        city: formData.get("city"),
        state: formData.get("state"),
        zipCode: formData.get("zipCode"),
        country: formData.get("country")
      }
    };

    try {
      await dispatch(addCompany(newClient)).unwrap();
      alert("Client added successfully!");
      onClose();
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 p-10">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-4xl overflow-y-auto">
        <h2 className="text-2xl font-bold">Add Client/Company</h2>
        <p className="mb-4 text-sm leading-6 text-gray-600">
          Create a Client/Company Profile 📝
        </p>
        
        
        
        
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <div className="w-full">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Name
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                id="name"
                name="name"
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="website"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Website
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                id="website"
                name="website"
                required
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></textarea>
              </div>
            </div>
          </div>

          <span className="flex items-center mb-4">
            <span className="pr-6">
              <p className="text-sm leading-6 text-gray-600">
                Contact Information 📞
              </p>
            </span>
            <span className="h-px flex-1 bg-black"></span>
          </span>

          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Type company email"
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Phone Number
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                id="phone"
                name="phone"
                required
              />
            </div>
          </div>

          <span className="flex items-center mb-4">
            <span className="pr-6">
              <p className="text-sm leading-6 text-gray-600">
                Business Address 📍
              </p>
            </span>
            <span className="h-px flex-1 bg-black"></span>
          </span>

          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <div className="w-full">
              <label
                htmlFor="street"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Street
              </label>
              <input
                type="text"
                name="street"
                id="street"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="city"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                required
              />
            </div>
          </div>

          <div className="grid gap-4 mb-4 sm:grid-cols-3 sm:gap-6 sm:mb-5">
            <div className="w-full">
              <label
                htmlFor="state"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                State
              </label>
              <input
                type="text"
                name="state"
                id="state"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="zipCode"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Zip Code
              </label>
              <input
                type="text"
                name="zipCode"
                id="zipCode"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="country"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                required
              />
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md mr-2 hover:bg-white"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 hover:text-white"
            >
              Save Changes
            </button>
          </div>
        
        
        
        </form>
      </div>
    </div>
  );
};

export default AddClientForm;
