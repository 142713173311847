import React from "react";

const WebsiteAboutUs = () => {
  return (
    <div>
      <section class="bg-[#304ffe]  my-0.5 ">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="bg-white rounded py-16 px-6 font-light text-gray-500 sm:text-lg ">
            <h2 class="mb-6 text-4xl tracking-tight font-extrabold text-gray-900 ">
              About Us
            </h2>
            <p class="mb-4">
              Established in 2018, Live Networking Solutions is a trusted name
              in San Antonio, Texas, dedicated to crafting digital success
              stories. With a passion for innovation, we specialize in web
              development, app development , and marketing services. Explore our
              journey and expertise. Book your consultation and join us in
              shaping a digital future since 2018.
            </p>
            <p>
              We are strategists, designers and developers. Innovators and
              problem solvers. Small enough to be simple and quick.
            </p>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
              alt="office content 1"
            />
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
              alt="office content 2"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebsiteAboutUs;
