import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useState } from "react";

const DropDown = ({ items }) => {
  const [selected, setSelected] = useState([items[0].name]);

  return (
    <div className="mx-auto w-64 bg-stone-400 rounded-lg">
      {items.length > 0? (
        <Listbox value={selected} onChange={setSelected}>
          <ListboxButton className="relative block w-full rounded-lg bg-white/5 py-1.5 pr-8 pl-3 text-left text-sm/6 text-white">
            {!selected ? "Non selected" : selected}
            <ExpandMoreOutlinedIcon
              className="group pointer-events-none absolute  right-2.5 size-4 fill-white/60"
              aria-hidden="true"
            />
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            transition
            className="w-[var(--button-width)] rounded-xl border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none'"
          >
            {items.map((item) => (
              <ListboxOption
                key={item.name}
                value={item}
                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
              >
                <CheckOutlinedIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                <div className="text-sm/6 text-black">{item.name}</div>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>
      ) : (
        <div>No Data Found</div>
      )}
    </div>
  );
};

export default DropDown;
