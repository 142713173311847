import React from "react";
import axios from "axios";
import { baseUrl } from "../../../../api";

const AddProjectForm = ({ onClose, id }) => {
  
  
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const newProject = {
      title: formData.get("name"),
      description: formData.get("description"),
      company: id,
      startDate: formData.get("startDate"),
      endDate: formData.get("endDate"),
      
    };

    try {
      await axios.post(`${baseUrl}projects/create`, newProject);
      alert("Client added successfully!");
      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 p-10">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-4xl overflow-y-auto">
        <h2 className="text-2xl font-bold">Add Project</h2>
        <p className="mb-4 text-sm leading-6 text-gray-600">
          Add Project to Company 📝
        </p>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <div className="w-full">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Title
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="name"
                name="name"
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="companyid"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Company id
              </label>
              <input
                disabled
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="companyid"
                name="companyid"
                defaultValue={id}
                required
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="p-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></textarea>
              </div>
            </div>
          </div>

          <span className="flex items-center mb-4">
            <span className="pr-6">
              <p className="text-sm leading-6 text-gray-600">
                Project Duration ⏲
              </p>
            </span>
            <span className="h-px flex-1 bg-black"></span>
          </span>

          <div className="w-full">
            <label
              htmlFor="startDate"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Start Date
            </label>
            <input
              type="date"
              name="startDate"
              id="startDate"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              required
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="endDate"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              End Date
            </label>
            <input
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              id="endDate"
              name="endDate"
              required
            />
          </div>

          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 "
            >Add Project
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProjectForm;
