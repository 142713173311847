import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../common/sidebar";
import Navbar from "../../common/navbar";
import { routes } from "./routes";
import { useSelector } from "react-redux";
import ErrorPage from "../../common/error-page";

const Admin = () => {
  const user = useSelector((state) => state.auth.user);


  return (
    <div className="flex h-screen overflow-hidden">
      {user.role === "admin" ? (
        <>
          <Sidebar routes={routes} />
          <div className="flex-1  overflow-y-auto">
            <Outlet />
          </div>
        </>
      ) : (
        <>
          
          <div  className="flex-1  overflow-y-auto">
            <ErrorPage/>
          </div>
        </>
      )}
    </div>
  );
};

export default Admin;
