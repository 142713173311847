import React from "react";
import TotalCards from "./components/totalCards";
import AddClientForm from "./components/addClientForm";
import axios from "axios";
import ProjectList from "./components/projectList";
import AddProjectForm from "./components/addProjectForm";
import CalendarComponent from "./components/calendarComponent";
import { useDispatch } from "react-redux";
import { setLogout } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Person, AccountTree, TaskAlt, Campaign } from "@mui/icons-material";
import Layout from "../../../common/layout"
import { useSelector } from "react-redux";
import { fetchCompanies } from "../../../features/companies/companiesSlice";
import { baseUrl } from "../../../api";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const [isAddClientFormOpen, setIsAddClientFormOpen] = React.useState(false);
  const [isAddProjectFormOpen, setIsAddProjectFormOpen] = React.useState(false);


  const [projects, setProjects] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [companyId, setCompanyId] = React.useState(null);


  const {
    list: companies,
    status,
    error,
  } = useSelector((state) => state.companies);

  React.useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCompanies());
    }
  }, [status, dispatch]);


  const [projectFetchError, setProjectFetchError] = React.useState(null)

  const handleFetchProjects = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}companies/${id}/projects`
      );
      setProjects(response.data);
      setCompanyId(id);
    } catch (e) {
      setProjectFetchError(`An error has occured when fetching Projects: ${e.message}`);
    }
  };

  const [eventFetchError, setEventFetchError] = React.useState(null)


  //Function not being used, will be updated to map with the calendar right below it
  const handleFetchEvents = async () => {

    try{
      const response = await axios.get(
        `${baseUrl}${companyId}/events/all`
      );
      setEvents(response.data);


      
    }
    catch(e){
      setEventFetchError(`An error has occured when fetching Events: ${e.message}`)

    }

  }

  const handleAddClient = () => {
    setIsAddClientFormOpen(true);
  };
  const handleAddProhect = () => {
    setIsAddProjectFormOpen(true);
  };

  const handleCloseForms = () => {
    setIsAddClientFormOpen(false);
    setIsAddProjectFormOpen(false);
  };

  const logout = () => {
    dispatch(setLogout());
    navigate("/login");
  };

  return (
    <Layout>
      <header>
        <div className="mb-5">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="text-center sm:text-left">
              <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                Welcome Back, Hector!
              </h1>
              <p className="mt-1.5 text-sm text-gray-500">
                Let's check in on your company! 🎉
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
              <a
                className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 px-5 py-3 text-gray-500 transition hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring"
                href="https://www.ouitrend.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-sm font-medium">View Website</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>

              <button
                className=" text-sm font-medium inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 px-5 py-3 text-gray-500 transition hover:bg-red-500 hover:text-white focus:outline-none focus:ring"
                type="button"
                onClick={() => logout()}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8 mb-4">
        <TotalCards
          count={companies.length}
          Icon={Person}
          title={"Total Clients"}
          color={"df81f6"}
          textColor={"ffffff"}
        />
        <TotalCards
          Icon={AccountTree}
          count={2}
          title={"Total Projects"}
          color={"F78181"}
          textColor={"ffffff"}
        />
        <TotalCards
          Icon={TaskAlt}
          title={"Total Tasks"}
          count={3}
          color={"EE9B39"}
          textColor={"ffffff"}
        />
        <TotalCards
          Icon={Campaign}
          count={2}
          title={"Total Campaigns"}
          color={"3E92F7"}
          textColor={"ffffff"}
        />
      </div>

      <div className="mb-2 flex justify-between items-center">
        <p className="text-md text-gray-500">
          Quick view of Client/Company Projects
        </p>
        <div className="mb-2 flex justify-between items-center">
          <button
            className="mr-2 block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring"
            onClick={handleAddClient}
          >
            Add Client
          </button>
          <button
            className="block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring"
            onClick={handleAddProhect}
          >
            Add Project
          </button>
        </div>
      </div>

      <section className=" ">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
          <div className="overflow-x-auto  rounded-lg shadow-md bg-white">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Client Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Email
                  </th>

                  <th className="px-4 py-2"> </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {companies.map((company) => (
                  <tr>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                      {company.name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {company.email}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2">
                      <button
                        href="#"
                        onClick={() => handleFetchProjects(company._id)}
                        className="inline-block rounded  px-4 py-2 text-xs font-medium text-black hover:bg-indigo-700 hover:text-white"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="h-32 rounded-lg shadow-md lg:col-span-2 bg-white">
            <ProjectList projects={projects} />
          </div>

          {isAddClientFormOpen && <AddClientForm onClose={handleCloseForms} />}
          {isAddProjectFormOpen && (
            <AddProjectForm onClose={handleCloseForms} id={companyId} />
          )}
        </div>
        <div className="py-20">
          <CalendarComponent events = {events} />
        </div>
      </section>
      </Layout>
  );
};

export default Home;
