import React from 'react'

const Information = () => {
  return (
    <div className="w-100 bg-white rounded-md p-5 mt-2 mb-2">
        <div className="columns-3 mt-2">
          <div>
            Name
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              // value={values.email}
              // onChange={handleChange("email")}
              class="p-1 text-start block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            Business Name
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              // value={values.email}
              // onChange={handleChange("email")}
              class="p-1 text-start block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            Domain
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              // value={values.email}
              // onChange={handleChange("email")}
              class="p-1 text-start block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="columns-3 mt-2">
          <div>
            Slogan
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              // value={values.email}
              // onChange={handleChange("email")}
              class="p-1 text-start block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            Phone Number
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              // value={values.email}
              // onChange={handleChange("email")}
              class="p-1 text-start block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            Number of employees
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              // value={values.email}
              // onChange={handleChange("email")}
              class="p-1 text-start block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <button
          type="submit"
          class="mt-3 flex w-full justify-center rounded-md bg-primary-buttons px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pb-hover-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
  )
}

export default Information