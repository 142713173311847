import React from "react";

const AccountList = () => {
  return (
    <div className="w-100 bg-white rounded-md p-5 mt-2 ">
      <div className="grid justify-items-end">
        <button
          type="submit"
          class=" flex  justify-center rounded-md bg-primary-buttons px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pb-hover-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add Account
        </button>
      </div>
      <div className="columns-3">
        <div>
          Platform
        </div>
        <div>
          Username
        </div>
        <div>
          Password
        </div>

      </div>
      <div className="columns-3">
        <div>
          Instagram
        </div>
        <div>
          hector.aalvarez0999@gmail.com
        </div>
        <div>
          password123
        </div>

      </div>
    </div>
  );
};

export default AccountList;
