import React from "react";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Layout from "../../../common/layout";
import AddClientForm from "../home/components/addClientForm";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompanies } from "../../../features/companies/companiesSlice";
import EditDrawer from "./components/edit-drawer";

import { useState } from "react";
import DeleteDialog from "./components/delete-dialog";

const Clients = () => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [company, setCompany] = React.useState(null);
  const [isAddClientFormOpen, setIsAddClientFormOpen] = React.useState(false);

  const dispatch = useDispatch();

  const {
    list: companies,
    status,
    error,
  } = useSelector((state) => state.companies);

  const handleEditClient = (company) => {
    setCompany(company);
    setIsOpen(true);
  };

  const handleAddClient = () => {
    setIsAddClientFormOpen(true);
  };
  const handleCloseForms = () => {
    setIsAddClientFormOpen(false);
  };

  React.useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCompanies());
    }
  }, [status, dispatch]);

  return (
    <Layout>
      <header>
        <div className="mb-5">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="text-center sm:text-left">
              <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                Clients | Companies
              </h1>
              <p className="mt-1.5 text-sm text-gray-500">
                Manage Client | Companies and User accounts! 📝
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
              <a
                className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 px-5 py-3 text-gray-500 transition hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring"
                href="https://www.ouitrend.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-sm font-medium">View Website</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>

              <button
                className="block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring"
                type="button"
                onClick={handleAddClient}
              >
                Add Client | Customer
              </button>
            </div>
          </div>
        </div>
      </header>

      {status === "loading" && <p>Loading companies...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" class="p-4">
                  <div class="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-blue-600  focus:ring-2 "
                    />
                    <label for="checkbox-all-search" class="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" class="px-6 py-3">
                  Company | Client
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Website
                </th>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Phone
                </th>
                <th scope="col" class="px-6 py-3">
                  Projects
                </th>

                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr class="bg-white border-b  hover:bg-gray-50 ">
                  <td class="w-4 p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-table-search-1"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
                      />
                      <label for="checkbox-table-search-1" class="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {company.name}
                  </th>
                  <td class="px-6 py-4">
                    <span
                      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                        company.active
                          ? "  bg-green-50  text-green-700  ring-green-600/20"
                          : " bg-red-50 text-red-700 ring-red-600/10"
                      }`}
                    >
                      {company.active ? "Enabled" : "Disabled"}
                    </span>
                  </td>
                  <td class="px-6 py-4">
                    <a href={`https://${company.website}`}>{company.website}</a>
                  </td>
                  <td class="px-6 py-4">{company.email}</td>
                  <td class="px-6 py-4">{company.phone}</td>
                  <td class="px-6 py-4">{company.projects.length}</td>

                  <td class="flex items-center px-6 py-4">
                    <button onClick={() => handleEditClient(company)}>
                      <a
                        href="#"
                        class="font-medium text-blue-600  hover:underline"
                      >
                        Edit
                      </a>
                    </button>

                    <button onClick={() => setDeleteDialogOpen(true)}>
                      <a
                        href="#"
                        class="font-medium text-red-600 hover:underline ms-3"
                      >
                        Delete
                      </a>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <EditDrawer company={company} isOpen={isOpen} setIsOpen={setIsOpen} />
      <DeleteDialog
        id={company ? company._id : "n/a"}
        isDeleteDialogOpen={isDeleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
      />

      {isAddClientFormOpen && <AddClientForm onClose={handleCloseForms} />}
    </Layout>
  );
};

export default Clients;
