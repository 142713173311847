import React from "react";

const WebsiteHeaderBanner = () => {
  return (
    <div>
      <section class="bg-white py-20">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-start lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
              Empowering Growth Through Digital Experience
            </h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              At OuiTrend, we are San Antonio's premier destination for web
              development, app development, and marketing services. Elevate your
              online presence with our innovative solutions tailored for
              success. Book your consultation today and unlock the potential to
              your digital journey.
            </p>

            <a
              href="#"
              class="bg-[#304ffe] hover:bg-[#2d94f3] text-white inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
            >
              Request a Consultation!
            </a>
          </div>
          <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/phone-mockup.png"
              alt="mockup"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebsiteHeaderBanner;
