import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import PinIcon from '@mui/icons-material/Pin';
import TaskIcon from '@mui/icons-material/Task';

export const routes = [
    {
        id: 0,
        icon: <SpaceDashboardIcon sx={{ color: "#112D4E"}}/>,
        text: "Dashboard",
        link: "dashboard"
    },
    {
        id: 1,
        icon: <AssignmentIcon sx={{ color: "#112D4E"}}/>,
        text: "Projects",
        link: "projects"
    },
    {
        id: 2,
        icon: <TaskIcon sx={{ color: "#112D4E"}}/>,
        text: "Work Manager",
        link: "work-manager"
    },
    {
        id: 3,
        icon: <PinIcon sx={{ color: "#112D4E"}}/>,
        text: "Brand",
        link: "brand"
    },    
    {
        id: 4,
        icon: <PersonIcon sx={{ color: "#112D4E"}}/>,
        text: "Profile",
        link: "profile"
    },
    

]