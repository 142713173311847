import React from 'react'

const TasksList = () => {

    const data = [
        {
          title: "General Ads - rapid hire and quality services",
          category: "Social Media Ads",
          categoryColor: "rgb(37, 99, 235)",
          date: "16 May 2024 8:56 AM",
        },
        {
          title: "Alternate Logo Creation",
          category: "Branding",
          categoryColor: "rgb(56, 189, 248)",
          date: "22 May 2024 12:52 PM",
        },
        {
          title: "SEO Optimization",
          category: "Search Engine Marketing",
          categoryColor: "rgb(99, 132, 255)",
          date: "12 June 2024 3:21 PM",
        },
        {
          title: "Content Marketing Strategy",
          category: "Content Marketing",
          categoryColor: "rgb(255, 99, 132)",
          date: "29 April 2024 10:05 AM",
        },
        {
          title: "PPC Campaign Setup",
          category: "Pay-Per-Click",
          categoryColor: "rgb(75, 192, 192)",
          date: "02 March 2024 9:30 AM",
        },
        {
          title: "Email Marketing",
          category: "Email Campaigns",
          categoryColor: "rgb(255, 206, 86)",
          date: "14 February 2024 1:45 PM",
        },
        {
          title: "Affiliate Marketing Program",
          category: "Affiliate Marketing",
          categoryColor: "rgb(153, 102, 255)",
          date: "21 January 2024 11:00 AM",
        },
        {
          title: "Website Redesign",
          category: "Web Development",
          categoryColor: "rgb(54, 162, 235)",
          date: "08 January 2024 2:25 PM",
        },
        {
          title: "Market Research",
          category: "Market Analysis",
          categoryColor: "rgb(201, 203, 207)",
          date: "30 December 2023 4:10 PM",
        },
        {
          title: "Product Launch",
          category: "Product Management",
          categoryColor: "rgb(255, 159, 64)",
          date: "15 December 2023 10:00 AM",
        },
        {
            title: "PPC Campaign Setup",
            category: "Pay-Per-Click",
            categoryColor: "rgb(75, 192, 192)",
            date: "02 March 2024 9:30 AM",
          },
          {
            title: "Email Marketing",
            category: "Email Campaigns",
            categoryColor: "rgb(255, 206, 86)",
            date: "14 February 2024 1:45 PM",
          },
          {
            title: "Affiliate Marketing Program",
            category: "Affiliate Marketing",
            categoryColor: "rgb(153, 102, 255)",
            date: "21 January 2024 11:00 AM",
          },
          {
            title: "General Ads - rapid hire and quality services",
            category: "Social Media Ads",
            categoryColor: "rgb(37, 99, 235)",
            date: "16 May 2024 8:56 AM",
          },
          {
            title: "Alternate Logo Creation",
            category: "Branding",
            categoryColor: "rgb(56, 189, 248)",
            date: "22 May 2024 12:52 PM",
          },
          {
            title: "SEO Optimization",
            category: "Search Engine Marketing",
            categoryColor: "rgb(99, 132, 255)",
            date: "12 June 2024 3:21 PM",
          },
          {
            title: "Content Marketing Strategy",
            category: "Content Marketing",
            categoryColor: "rgb(255, 99, 132)",
            date: "29 April 2024 10:05 AM",
          },
          {
            title: "PPC Campaign Setup",
            category: "Pay-Per-Click",
            categoryColor: "rgb(75, 192, 192)",
            date: "02 March 2024 9:30 AM",
          },
          {
            title: "Email Marketing",
            category: "Email Campaigns",
            categoryColor: "rgb(255, 206, 86)",
            date: "14 February 2024 1:45 PM",
          },
          {
            title: "Affiliate Marketing Program",
            category: "Affiliate Marketing",
            categoryColor: "rgb(153, 102, 255)",
            date: "21 January 2024 11:00 AM",
          },
          {
            title: "Website Redesign",
            category: "Web Development",
            categoryColor: "rgb(54, 162, 235)",
            date: "08 January 2024 2:25 PM",
          },
          {
            title: "Market Research",
            category: "Market Analysis",
            categoryColor: "rgb(201, 203, 207)",
            date: "30 December 2023 4:10 PM",
          },
          {
            title: "Product Launch",
            category: "Product Management",
            categoryColor: "rgb(255, 159, 64)",
            date: "15 December 2023 10:00 AM",
          },
          {
              title: "PPC Campaign Setup",
              category: "Pay-Per-Click",
              categoryColor: "rgb(75, 192, 192)",
              date: "02 March 2024 9:30 AM",
            },
      ];
      

  return (
    <div className="w-full rounded shadow relative bg-white ">
      <div class="p-4">
        <table class="min-w-full border-collapse">
          <thead>
            <tr>
              <th scope="col" class="px-3 py-3.5 text-left text-gray-700">
                Title
              </th>{" "}
              <th scope="col" class="px-3 py-3.5 text-left text-gray-700">
                Type
              </th>{" "}
              <th scope="col" class="px-3 py-3.5 text-left text-gray-700">
                Last updated
              </th>
            </tr>
          </thead>{" "}
          <tbody>
          {data.map((item, index) => (
          <tr key={index} className="border-0 border-t border-solid border-gray-200 cursor-pointer transition hover:bg-gray-100">
            <td className="whitespace-nowrap px-3 py-4 text-gray-700">
              {item.title}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-gray-700">
              <span className="inline-block items-center rounded font-medium leading-tight px-2 py-1 bg-transparent text-gray-600">
                <span
                  className="inline-block rounded-full mr-1 w-2.5 h-2.5"
                  style={{ backgroundColor: item.categoryColor }}
                ></span>
                {item.category}
              </span>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-gray-700">
              {item.date}
            </td>
          </tr>
        ))}


            
          </tbody>
        </table>
      </div>
      </div>
  )
}

export default TasksList