import React from "react";
import WebsiteNavbar from "./components/website-navbar";
import WebsiteFooter from "./components/website-footer";

export const WebsiteLayout = ({ children }) => (
    <div className="bg-[#495464]">
     <header >
      <WebsiteNavbar/>
     </header>
      <main>{children}</main>
      <footer >
        <WebsiteFooter/>
        
      </footer>
    </div>
  );
  