import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../common/sidebar.jsx";
import Navbar from "../../common/navbar.jsx";
import { routes } from "./routes.js";

const Client = () => {
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar routes={routes} />
      <div className="flex-1  overflow-y-auto">
       
        <Outlet />
      </div>
    </div>
  );
};

export default Client;
