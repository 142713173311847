import React from "react";
import { useSelector } from "react-redux";
import Navbar from "../../../common/navbar";
import TasksList from "./components/tasks-list";
import ProjectList from "./components/project-list";

const Dashboard = (props) => {
  // const { user } = useSelector((state) => state.auth);

  return (
    <div className={" flex-1 p-10 dark-purple  bg-gray-100 "}>

      <div class="text-3xl font-medium mb-4 mt-4">
        Welcome Hector! Let's Get Started!
      </div>

      <div className="flex flex-row gap-4 mb-10">
        <div className="bg-white rounded shadow p-4 mb-2 ">
          <div className="flex flex-row gap-2">
            <div className="font-semibold">Total Projects: </div>
            <div>6</div>
          </div>
        </div>
        <div className="bg-white rounded shadow p-4 mb-2">
          <div className="flex flex-row gap-2">
            <div className="font-semibold">Total Tasks: </div>
            <div>6</div>
          </div>
        </div>
        <div className="bg-white rounded shadow p-4 mb-2 ">
          <div className="flex flex-row gap-2">
            <div className="font-semibold">Update: </div>
            <div>6</div>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-2">
        
        <div className="basis-5/12">
          <div className="font-bold text-xl mb-4">Project List</div>
          <ProjectList/>
        </div>
        <div className="basis-7/12">
          <div className="font-bold text-xl mb-4 ">Project Tasks</div>
          
            <TasksList />
          
          
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
