import React from 'react'

const ProjectList = () => {

    const data = [
        {
          title: "Website Creation",
          date: "16 May 2024 8:56 AM",
        },
        {
          title: "Marketing Campaign",
          date: "22 May 2024 12:52 PM",
        },
        {
          title: "SEO Optimization",
          date: "12 June 2024 3:21 PM",
        },
        {
          title: "Content Marketing Strategy",
          date: "29 April 2024 10:05 AM",
        },
        {
          title: "PPC Campaign Setup",
          date: "02 March 2024 9:30 AM",
        },
        {
          title: "Email Marketing",
          date: "14 February 2024 1:45 PM",
        },
        {
          title: "Affiliate Marketing Program",
          date: "21 January 2024 11:00 AM",
        },
      ];
      

  return (
    <div className="w-full rounded shadow relative bg-white">
    <div class="p-4">
      <table class="min-w-full border-collapse">
        <thead>
          <tr>
            <th scope="col" class="px-3 py-3.5 text-left text-gray-700">
              Title
            </th>{" "}
            <th scope="col" class="px-3 py-3.5 text-left text-gray-700">
              Created On
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-gray-700">
              Action
            </th>
          </tr>
        </thead>{" "}
        <tbody>
        {data.map((item, index) => (
        <tr key={index} className="border-0 border-t border-solid border-gray-200 cursor-pointer transition hover:bg-gray-100">
          <td className="whitespace-nowrap px-3 py-4 text-gray-700">
            {item.title}
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-gray-700">
            <span className="inline-block items-center rounded font-medium leading-tight px-2 py-1 bg-transparent text-gray-600">
              <span
                className="inline-block rounded-full mr-1 w-2.5 h-2.5"
                style={{ backgroundColor: item.categoryColor }}
              ></span>
              {item.category}
            </span>
          </td> */}
          <td className="whitespace-nowrap px-3 py-4 text-gray-700">
            {item.date}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-gray-700">
            <button className='bg-primary-buttons py-1 px-4 rounded-md text-white hover:bg-pb-hover-color cursor-pointer'>View</button>
          </td>
        </tr>
      ))}


          
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default ProjectList