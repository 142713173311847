import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const CalendarComponent = (props) => {
  
  
  
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState({
    '2024-07-15': ['Meeting', 'Lunch'],
    '2024-07-20': ['Birthday party'],
  });
  const [isMonthSelectOpen, setIsMonthSelectOpen] = useState(false);
  const [isYearSelectOpen, setIsYearSelectOpen] = useState(false);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const handlePrevMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
  };

  const handleMonthChange = (monthIndex) => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), monthIndex, 1));
    setIsMonthSelectOpen(false);
  };

  const handleYearChange = (year) => {
    setCurrentDate(prevDate => new Date(year, prevDate.getMonth(), 1));
    setIsYearSelectOpen(false);
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="h-24 bg-gray-50 rounded-lg"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const dayEvents = events[date] || [];
      const isToday = new Date().toDateString() === new Date(year, month, day).toDateString();

      days.push(
        <div key={day} className={`h-24 bg-white rounded-lg p-2 overflow-hidden transition-all duration-200 hover:shadow-md ${isToday ? 'ring-2 ring-blue-400' : ''}`}>
          <div className={`font-semibold ${isToday ? 'text-blue-600' : 'text-gray-700'}`}>{day}</div>
          {dayEvents.map((event, index) => (
            <div key={index} className="text-xs text-gray-600 truncate bg-blue-100 rounded px-1 py-0.5 mt-1">{event}</div>
          ))}
        </div>
      );
    }

    return days;
  };

  return (
    <div className=" w-full bg-white  rounded overflow-hidden ">
      <div className="flex justify-between items-center p-4 bg-indigo-600 text-white">
        <button onClick={handlePrevMonth} className="p-2 hover:bg-white/20 rounded-full transition-colors duration-200">
          <ExpandMoreIcon className="w-6 h-6" />
        </button>
        <div className="flex space-x-2">
          <div className="relative">
            <button
              onClick={() => setIsMonthSelectOpen(!isMonthSelectOpen)}
              className="w-36 bg-white/10 rounded px-3 py-2 flex items-center justify-between"
            >
              {months[currentDate.getMonth()]}
              <ArrowForwardIosIcon className="w-4 h-4" />
            </button>
            {isMonthSelectOpen && (
              <div className="absolute top-full mt-1 w-full bg-white rounded-md shadow-lg z-10">
                {months.map((month, index) => (
                  <button
                    key={month}
                    onClick={() => handleMonthChange(index)}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    {month}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="relative">
            <button
              onClick={() => setIsYearSelectOpen(!isYearSelectOpen)}
              className="w-24 bg-white/10 rounded px-3 py-2 flex items-center justify-between"
            >
              {currentDate.getFullYear()}
              <ArrowBackIosIcon className="w-4 h-4" />
            </button>
            {isYearSelectOpen && (
              <div className="absolute top-full mt-1 w-full bg-white rounded-md shadow-lg z-10">
                {Array.from({ length: 10 }, (_, i) => currentDate.getFullYear() - 5 + i).map(year => (
                  <button
                    key={year}
                    onClick={() => handleYearChange(year)}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    {year}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <button onClick={handleNextMonth} className="p-2 hover:bg-white/20 rounded-full transition-colors duration-200">
          <ArrowForwardIosIcon className="w-6 h-6" />
        </button>
      </div>
      <div className="p-4">
        <div className="grid grid-cols-7 gap-2 mb-2">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="text-center font-semibold text-gray-500">{day}</div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-2">
          {renderCalendar()}
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;