import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import TaskCard from "../components/taskCard"

const TaskColumn = ({ id, title, tasks }) => {
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`bg-gray-200 h-max p-4 rounded-lg w-1/4 overflow-y-auto ${
            snapshot.isDraggingOver ? 'bg-gray-300' : ''
          }`}
        >
          <h2 className="font-semibold mb-4">{title}</h2>
          {tasks.map((task, index) => (
            <TaskCard key={task._id} task={task} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};



export default TaskColumn;