import "./App.css";

import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ShieldIcon from '@mui/icons-material/Shield';
import LoginIcon from '@mui/icons-material/Login';
import React from "react";


function App() {
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

 


  return user ? (
    <div class="bg-white">
      <Outlet />
    </div>
  ) : (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4">
    <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 space-y-6">
      <div className="flex justify-center">
        <ShieldIcon/>
      </div>
      <h1 className="text-3xl font-bold text-center text-gray-900">You're Not Signed In</h1>
      <p className="text-center text-gray-600">
        Please sign in to view your information
      </p>
      <button
        onClick={handleLogin}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
      >
        <LoginIcon/>
     
      </button>
    </div>
  </div>
  );
}

export default App;
