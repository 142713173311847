import React, { Children } from "react";

const Layout = ({ children }) => {
  return (
    <div className="px-10 py-12 h-full overflow-y-scroll bg-gradient-to-br from-stone-50 via-neutral-100 to-gray-200">
      {children}
    </div>
  );
};

export default Layout;
