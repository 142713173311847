import React from 'react';
import axios from 'axios';

const ProjectList = ({ projects }) => {

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    return dateObj.toLocaleDateString("en-US");
  };


  

  if (!projects || projects.length === 0) {
    return <div>
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="text-left rtl:text-right">
          <tr>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Title
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Start Date
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              End Date
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Tasks
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Calendar Events
            </th>
            <th className="px-4 py-2"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <div>
          
          </div>
        
        </tbody>
      </table>
      <div className='text-center'>
          <p className="text-center text-gray-500 font-bold  mt-4">Select a Client / Company to View</p>
          
          </div>
    </div> ;
  }

  return (
    <>
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="text-left rtl:text-right my-2">
          <tr>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Title
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Start Date
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              End Date
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Status
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Completion Rate
            </th>
            
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {projects.map((project) => (
            <tr key={project._id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                {project.title}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {formatDate(project.startDate)}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {formatDate(project.endDate)}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              <span class="capitalize inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{project.status}</span>
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {project.calendarEvents.length}
              </td>
             
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ProjectList;
