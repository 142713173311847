// src/features/companies/companiesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async () => {
    const response = await axios.get('http://localhost:3001/companies/all');
    return response.data;
  }
);

export const addCompany = createAsyncThunk(
  'companies/addCompany',
  async (newCompany) => {
    const response = await axios.post('http://localhost:3001/companies/create', newCompany);
    return response.data;
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    list: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.list.push(action.payload);
      });
  }
});

export default companiesSlice.reducer;