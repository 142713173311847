import React, { Children } from "react";

import PersonIcon from '@mui/icons-material/Person';
const TotalCards = ({ count, title, color, textColor, Icon}) => {
  return (
    <div className="shadow-sm rounded-lg border bg-white p-6">
      <article className="flex items-center gap-4 ">
        <span 
          className={`rounded-full p-3 text-blue-600`} 
          style={{ backgroundColor: `#${color}`, color: `#${textColor}` }}

        >
          <Icon/>
        </span>

        <div>
          <p className="text-2xl font-medium text-gray-900">{count}</p>

          <p className="text-sm text-gray-500">{title}</p>
        </div>
      </article>
    </div>
  );
};

export default TotalCards;

