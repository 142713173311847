import React, { useState } from "react";
import { redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "../../features/auth/authSlice";
import * as yup from "yup";
import { Formik } from "formik";
import logo from "../../assets/logo.png";
import { fetchCompanies } from "../../features/companies/companiesSlice";

// import styles from "../../css/login.module.css";
import {
  BottomRightGreenSVG,
  MiddleCircleWidget,
  ProfitWidget,
  TopLeftGreenSVG,
  ViewWidget,
} from "../../assets/login-svgs";

//COMPLETED ON JANUARY 28 @ 1 AM
const url = "http://localhost:3001";

const LoginPage = () => {
  const registration = () => redirect("/registration");
  const dispatch = useDispatch();

  const loginSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
  });

  const initialValuesLogin = {
    email: "",
    password: "",
  };

  const signIn = async (values, token) => {
    const response = await fetch(url + "/auth/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    return response.json();
  };

  const navigate = useNavigate();

  const handleSignIn = async (values, onSubmitProps) => {
    const loggedIn = await signIn(values);

    // const role = loggedIn.user.permissions.role;
    onSubmitProps.resetForm();
    if (loggedIn) {
      dispatch(
        setLogin({
          user: loggedIn.user,
          token: loggedIn.token,
        })
      );
      
      console.log("Signed In");
      navigate("/app/admin/home");
    }
    if(loggedIn.user.role === 'admin'){
      dispatch(fetchCompanies())
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await handleSignIn(values, onSubmitProps);
  };

  return (
    <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-40 w-auto" src={logo} alt="Your Company" />
        <h2 class="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValuesLogin}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form
              onSubmit={handleSubmit}
              class="space-y-6"
              action="#"
              method="POST"
            >
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    value={values.email}
                    onChange={handleChange("email")}
                    class="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div class="flex items-center justify-between">
                  <label
                    for="password"
                    class="text-centerblock text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div class="text-sm">
                    <a
                      href="#"
                      class="font-semibold text-[#3F72AF] hover:text-[#112D4E]"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div class="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    value={values.password}
                    onChange={handleChange("password")}
                    class="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-primary-buttons px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pb-hover-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginPage;
