import React from "react";
import { Draggable } from "react-beautiful-dnd";

const TaskCard = ({ task, index }) => {
  return (
    <Draggable draggableId={task._id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="bg-white p-4 rounded-lg shadow-sm mb-4"
        >
          <h3 className="font-semibold mb-2">{task.title}</h3>
          <p className="text-sm text-gray-600 mb-2">{task.description} </p>
          <span
            className={`px-2 py-1 rounded-full text-xs ${
              task.priority === "high"
                ? "bg-red-200"
                : task.priority === "medium"
                ? "bg-yellow-200"
                : task.priority === "low"
                ? "bg-green-200"
                : "bg-stone-200"
            }`}
          >
            {task.priority.toUpperCase()}
          </span>
          <div className="flex flex-wrap gap-2 mt-2">
            {task.tags.map((tag, tagIndex) => (
              <span
                key={tagIndex}
                className={`px-2 py-1 rounded-full text-xs ${tag.color}`}
              >
                {tag.name}
              </span>
            ))}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
